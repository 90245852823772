@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --content-width: 1216px;
  --content-large: 1344px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --container-large: calc(var(--content-large)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Work Sans", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #000;
  --color-dark: #333;
  --color-dark-600: #2c2c2e;
  --color-blue-50: #f7fcff;
  --color-blue-100: #d6e4ec;
  --color-blue-400: #006994;
  --color-blue-512: #2da7df1f;
  --color-blue-700: #001f2d;
  --color-blue-764: #001f2da3;
  --color-blue-900: #292e3d;
  --radius-main: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-full: 50%;
  --shadow-primary: 0px 8px 16px 0px #00000014;
  --shadow-blue: 0px 4px 40px 0px #2b59ff14;
  --gradient-main: linear-gradient(270deg, #e7f3f900 0%, #e7f3f9 100%);
  --gradient-full: linear-gradient(240deg, #e7f3f924 -25%, #e7f3f8 85%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-blue-900);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(18px, 2vi, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page--rental .section--featured :nth-last-child(2) {
  margin-bottom: 0;
}

.page--rental .section--featured .btn {
  display: none;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.container--large {
  max-width: var(--container-large);
}

@media only screen and (max-width: 992px) {
  .container--large {
    max-width: var(--container-width);
  }
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  display: grid;
}

.main--no-hero {
  padding-top: var(--header-height);
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient-main);
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .overlay:before {
    background: var(--gradient-full);
  }
}

.form {
  max-width: 800px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  box-shadow: var(--shadow-blue);
  margin-inline: auto;
  padding: clamp(12px, 2.4vw, 34px) clamp(10px, 2.3vw, 32px);
}

.form > :not(:nth-last-child(2), :last-child) {
  margin-bottom: 16px;
}

.form > :nth-last-child(2) {
  margin-bottom: 24px;
}

label {
  display: block;
}

input, textarea {
  width: 100%;
  background-color: var(--color-blue-100);
  border-radius: var(--radius-main);
  display: block;
}

input {
  padding: 14.5px;
}

textarea {
  min-height: 156px;
  padding: 16px;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, .title {
  font-weight: var(--fw-600);
  overflow: hidden;
}

h1 span, h2 span, h3 span, .title span {
  color: var(--color-blue-400);
  display: block;
}

h1, h2 {
  line-height: 1.21;
}

h1 {
  color: var(--color-blue-900);
  font-size: clamp(32px, 4vi, 56px);
}

h2 {
  color: var(--color-dark);
  font-size: clamp(26px, 2.8vi, 40px);
}

h3, .title {
  letter-spacing: .02em;
  font-size: clamp(20px, 2vi, 24px);
  line-height: 1.5;
}

.text {
  font-size: 14px;
  line-height: 1.6;
}

.desc {
  font-family: var(--font-family-secondary);
}

.accent, a:not(.nav__link, .btn, .copyright, .blog__back) {
  color: var(--color-blue-400);
}

a, button {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  overflow: hidden;
}

a.more {
  text-transform: capitalize;
  text-underline-offset: 3px;
  text-decoration: underline;
}

a:not(.nav__link, .btn, .copyright, .blog__back) {
  transition: color .4s ease-in-out;
}

a:not(.nav__link, .btn, .copyright, .blog__back):hover, a:not(.nav__link, .btn, .copyright, .blog__back):focus {
  text-underline-offset: 3px;
  color: var(--color-blue-700);
  text-decoration: underline;
}

input, textarea {
  color: var(--color-blue-400);
  font-size: 16px;
  line-height: 1;
}

input::placeholder, textarea::placeholder {
  color: var(--color-blue-764);
}

.copyright {
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.17;
}

.copyright:not(a) {
  font-family: var(--font-family-secondary);
}

.copyright br {
  display: none;
}

@media only screen and (max-width: 576px) {
  .copyright {
    line-height: 1.6;
  }

  .copyright br {
    display: block;
  }
}

.header {
  z-index: 10;
  width: 100%;
  padding-top: 24px;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

@media only screen and (max-width: 992px) {
  .header {
    padding-top: 10px;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav .logo {
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100%;
  margin-right: 24px;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.nav__list {
  align-items: center;
  margin: 0 clamp(16px, 2.5vw, 34px) 0 0;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    margin: 0 0 30px;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: clamp(16px, 2vw, 24px);
}

.nav__link {
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1.21;
  font-weight: var(--fw-400);
  color: var(--color-dark);
  white-space: nowrap;
  transition: color .3s ease-in-out;
}

.nav__link.true, .nav__link:hover {
  color: var(--color-blue-400);
}

.nav__btns {
  align-items: center;
  gap: 8px;
  display: flex;
}

.nav:not(.nav--footer) {
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  box-shadow: var(--shadow-primary);
  padding: 16px clamp(16px, 4.5vw, 64px);
}

@media only screen and (max-width: 1180px) {
  .nav:not(.nav--footer) {
    padding: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .nav:not(.nav--footer) .logo {
    max-width: 200px;
  }

  .nav:not(.nav--footer) .nav__inner {
    z-index: -1;
    padding: calc(var(--header-height) * 1 + 30px) 16px 30px 16px;
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav:not(.nav--footer) .nav__list {
    z-index: -1;
    flex-direction: column;
  }

  .nav:not(.nav--footer) .nav__item:not(:last-of-type) {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .nav:not(.nav--footer) .nav__btns {
    flex-direction: column;
    gap: 30px;
  }

  .nav--footer {
    flex-direction: column;
    justify-content: center;
  }

  .nav--footer .logo {
    margin-bottom: 24px;
    margin-right: 0;
  }

  .nav--footer .nav__inner {
    flex-direction: column;
    justify-content: center;
  }

  .nav--footer .nav__list, .nav--footer .nav__btns {
    justify-content: center;
  }
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-wrap: wrap;
    gap: 24px;
  }
}

[class].btn {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--color-blue-400);
  border-radius: var(--radius-main);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  color: var(--color-blue-700);
  background-color: #0000;
  margin: 0;
  padding: 10px 15px;
  font-size: clamp(16px, 2vi, 18px);
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: block;
  position: relative;
}

[class].btn--reverse, [class].btn:hover {
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

[class].btn--reverse:hover {
  background-color: var(--color-white);
  color: var(--color-blue-700);
}

[class].btn--l {
  padding: 18px 23px;
}

[class].btn--xl {
  padding: 17px 31px;
}

[class].btn--centered {
  margin-inline: auto;
}

[class].btn--send {
  text-transform: initial;
  padding: 14px 23px;
}

@media only screen and (max-width: 768px) {
  [class].btn--send {
    width: 100%;
  }
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-blue-400);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened.nav:not(.nav--footer) .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.section {
  padding: clamp(34px, 6vw, 86px) 0;
}

.section__info {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 26px;
  margin-bottom: clamp(24px, 3.4vw, 48px);
  display: flex;
}

.section--more > :nth-last-child(2) {
  margin-bottom: clamp(24px, 3.4vw, 47px);
}

.section--discover .section__title, .section--master .section__title, .section--team .section__title {
  font-size: clamp(26px, 3.4vi, 48px);
}

.section--discover .section__info {
  gap: clamp(24px, 3.4vw, 50px);
}

.section--accent {
  background-color: var(--color-blue-400);
}

.section--accent .section__title {
  color: var(--color-white);
}

.section--journey {
  padding: clamp(34px, 5.3vw, 78px) 0;
}

.section--values {
  background-color: var(--color-blue-50);
}

.section--team {
  padding-bottom: clamp(34px, 7.4vw, 108px);
}

.section--team .section__info {
  margin-bottom: clamp(24px, 4.5vw, 64px);
}

.hero {
  padding: 0;
}

.hero__inner {
  min-height: clamp(450px, 50.4vw, 726px);
  padding-top: var(--header-height);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 2;
  max-width: 560px;
  width: 100%;
  position: relative;
}

.hero__content h1 {
  margin-bottom: 24px;
}

.hero__content .desc {
  margin-bottom: 32px;
}

@media only screen and (max-width: 992px) {
  .hero__content {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .hero__content {
    width: 100%;
  }
}

.cards__items {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  gap: 24px clamp(12px, 1.7vw, 24px);
  display: grid;
}

.cards__item {
  border: 1px solid var(--color-blue-100);
  border-radius: var(--radius-main);
  overflow: hidden;
}

.cards__item img {
  width: 100%;
}

.cards__content {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 24px clamp(12px, 1.7vw, 24px);
  display: flex;
}

.cards__content h3:not(.cards__person) {
  color: var(--color-blue-900);
}

.cards__content p {
  color: var(--color-dark);
}

.cards__name {
  font-size: clamp(18px, 2vi, 20px);
  line-height: 1.4;
}

.cards__text {
  font-size: 16px;
  line-height: 1.6;
}

.cards__person, .cards__position {
  color: var(--color-dark);
}

.cards__position {
  font-weight: var(--fw-600);
  letter-spacing: .02em;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1;
}

.cards--three .cards__items {
  grid-template-columns: repeat(3, 1fr);
}

.cards--colored .cards__item {
  background-color: var(--color-blue-100);
  border: none;
}

.cards--accent {
  background-color: var(--color-blue-100);
  border-radius: var(--radius-main);
  padding: clamp(24px, 3.4vw, 48px);
  overflow: hidden;
}

.cards--accent .cards__title {
  font-size: clamp(22px, 2.2vi, 32px);
  line-height: 1.21;
  font-weight: var(--fw-600);
  color: var(--color-blue-400);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.cards--accent .cards__item {
  border: none;
}

.cards--accent .cards__content {
  background-color: var(--color-blue-400);
  height: 100%;
  gap: clamp(12px, 1.4vw, 16px);
  padding: clamp(12px, 1.7vw, 24px) clamp(12px, 1.4vw, 16px);
}

.cards--accent .cards__content h3, .cards--accent .cards__content p {
  color: var(--color-white);
}

.cards--rounded .cards__item {
  border-radius: var(--radius-l);
}

.cards--rounded .cards__item img {
  height: revert-layer;
}

.cards--rounded .cards__content {
  text-align: left;
}

.cards--rounded .cards__content p {
  font-family: var(--font-family-secondary);
}

.cards--rounded .cards__name {
  letter-spacing: 0;
  line-height: 1.2;
}

.cards--shadow .cards__item {
  box-shadow: var(--shadow-primary);
  border: none;
}

.cards--shadow .cards__content {
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .cards--accent .cards__content {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .cards__items {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    align-items: stretch;
  }

  .cards--three .cards__items {
    grid-template-columns: repeat(2, 1fr);
  }

  .cards--three .cards__item:last-child {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 576px) {
  .cards--three .cards__items {
    grid-template-columns: 1fr;
  }

  .cards--three .cards__item:last-child {
    grid-column: auto;
  }
}

@media only screen and (max-width: 475px) {
  .cards__items {
    grid-template-columns: 1fr;
  }
}

.box {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.box__content {
  max-width: 596px;
  width: 100%;
}

.box__title {
  margin-bottom: 24px;
  font-size: clamp(26px, 3.4vi, 48px);
}

.box__desc {
  font-family: var(--font-family-secondary);
  margin-bottom: clamp(24px, 2.3vw, 32px);
}

.box__items li:not(:last-of-type) {
  margin-bottom: clamp(16px, 2.3vw, 32px);
}

.box__items li p {
  color: var(--color-blue-700);
  line-height: 1.6;
  display: inline;
}

.box__items li span {
  font-weight: var(--fw-700);
  color: var(--color-blue-400);
}

.box img {
  border-radius: var(--radius-m);
}

.box--wide .box__content {
  max-width: 699px;
}

.box--wide .box__title {
  margin-bottom: clamp(24px, 2.3vw, 32px);
}

@media only screen and (max-width: 992px) {
  .box {
    flex-direction: column;
  }

  .box__content {
    max-width: 100%;
  }

  .box img {
    width: 100%;
  }

  .box--journey img {
    aspect-ratio: 3 / 2;
  }

  .box--reverse {
    flex-direction: column-reverse;
  }
}

.blog {
  padding: clamp(34px, 5vw, 72px) 0;
}

.blog__nav {
  grid-template-columns: repeat(2, auto);
  align-items: flex-start;
  gap: clamp(16px, 3vw, 40px);
  margin-bottom: clamp(24px, 3.4vw, 48px);
  display: grid;
}

.blog__back {
  padding-block: 11px;
  font-size: 18px;
  line-height: 1;
  font-weight: var(--fw-500);
  color: var(--color-dark-600);
  padding-left: 52px;
  transition: color .4s ease-in-out;
  position: relative;
}

.blog__back:before {
  content: "";
  width: 18px;
  height: 18px;
  filter: none;
  background-image: url("../images/icons/back-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: filter .4s ease-in-out;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}

.blog__back:hover {
  color: var(--color-blue-400);
}

.blog__back:hover:before {
  filter: brightness(0) saturate() invert(24%) sepia(96%) saturate(1717%) hue-rotate(175deg) brightness(90%) contrast(102%);
}

.blog h1 {
  width: 76%;
  font-weight: var(--fw-700);
  color: var(--color-black);
  font-size: clamp(26px, 3vi, 36px);
}

.blog__img {
  border-radius: var(--radius-l);
  margin-bottom: clamp(32px, 4.5vw, 64px);
}

.blog__body > :not(:last-child, .blog__img) {
  margin-bottom: clamp(24px, 3.4vw, 48px);
}

.blog__body p, .blog__body li, .blog__body strong, .blog__body a {
  color: var(--color-dark-600);
  letter-spacing: .02em;
  line-height: 1.21;
}

@media only screen and (max-width: 768px) {
  .blog h1 {
    width: 100%;
  }

  .blog__back {
    padding-left: 34px;
  }

  .blog__back:before {
    left: 0;
  }

  .blog__img {
    border-radius: var(--radius-main);
  }
}

@media only screen and (max-width: 475px) {
  .blog__nav {
    grid-template-columns: 1fr;
  }

  .blog__img {
    aspect-ratio: 1 / 1;
  }
}

.keys {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: clamp(32px, 4.5vw, 64px) clamp(12px, 2.3vw, 32px);
  display: grid;
}

.keys__item {
  text-align: center;
  padding-top: 120px;
  position: relative;
}

.keys__item:before {
  content: "";
  width: 96px;
  height: 96px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--color-blue-512);
  border-radius: var(--radius-m);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.keys__item--innovation:before {
  background-image: url("../images/icons/innovation-icon.svg");
}

.keys__item--integrity:before {
  background-image: url("../images/icons/integrity-icon.svg");
}

.keys__item--quality:before {
  background-image: url("../images/icons/quality-icon.svg");
}

.keys__item--sustainability:before {
  background-image: url("../images/icons/sustainability-icon.svg");
}

.keys__item--customer:before {
  background-image: url("../images/icons/customer-icon.svg");
}

.keys__item--teamwork:before {
  background-image: url("../images/icons/teamwork-icon.svg");
}

.keys__item .title {
  font-size: clamp(16px, 2vi, 24px);
}

@media only screen and (max-width: 576px) {
  .keys__item {
    padding-top: 80px;
  }

  .keys__item:before {
    width: 62px;
    height: 62px;
    background-size: 50%;
  }

  .keys {
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.faq__item {
  background-color: var(--color-blue-100);
  border-radius: var(--radius-main);
  overflow: hidden;
}

.faq__item:not(:last-child) {
  margin-bottom: 16px;
}

.faq details {
  overflow: hidden;
}

.faq details[open] summary {
  padding: 16px 56px 13px 24px;
}

.faq details[open] summary:before {
  filter: brightness(0) saturate() invert(15%) sepia(92%) saturate(4918%) hue-rotate(186deg) brightness(92%) contrast(101%);
  rotate: none;
}

.faq details[open] summary + div {
  padding: 0 56px 16px 24px;
}

.faq summary {
  letter-spacing: .02em;
  font-size: 18px;
  line-height: 1;
  font-weight: var(--fw-600);
  color: var(--color-blue-700);
  cursor: pointer;
  padding: 16px 56px 16px 24px;
  position: relative;
}

.faq summary:before {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/collapse-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: rotate .2s ease-in-out, filter .4s ease-in;
  position: absolute;
  top: 12px;
  right: 24px;
  overflow: hidden;
  rotate: 180deg;
}

.faq summary + div {
  padding: 0;
}

.faq summary + div p {
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.6;
}

@media only screen and (max-width: 992px) {
  .faq h2 {
    margin-bottom: 24px;
  }

  .faq__item:not(:last-child) {
    margin-bottom: 16px;
  }

  .faq summary {
    line-height: 1.6;
  }

  .faq summary:before {
    top: 16px;
    right: 16px;
  }

  .faq details[open] summary + div {
    padding-right: 16px;
  }
}

.policy h2, .policy p, .policy li, .policy a {
  font-family: var(--font-family-secondary);
  letter-spacing: .02em;
}

.policy h2, .policy p, .policy li {
  color: var(--color-dark);
}

.policy h1 {
  text-align: center;
  margin-bottom: clamp(24px, 3.4vw, 48px);
  font-size: clamp(32px, 3.4vi, 48px);
}

.policy h2 {
  font-family: var(--font-family-secondary);
  color: var(--color-blue-400);
  margin-bottom: 24px;
  font-size: clamp(26px, 3vi, 30px);
}

.policy .policy__content:not(:last-child) {
  margin-bottom: clamp(26px, 3.4vi, 48px);
}

.policy .policy__content > :not(:last-child, h2) {
  margin-bottom: clamp(16px, 3vw, 32px);
}

.policy ul {
  padding-left: clamp(16px, 3vw, 32px);
}

.policy li {
  padding-left: 32px;
  position: relative;
}

.policy li:before {
  content: "✔";
  position: absolute;
  left: 0;
}

.policy li:not(:last-of-type) {
  margin-bottom: 16px;
}

.policy a {
  text-underline-offset: 3px;
  text-decoration: underline;
  display: inline;
}

.policy strong {
  font-weight: var(--fw-500);
}

.footer__inner {
  padding: clamp(16px, 3.8vw, 56px) 0;
}

.footer__bottom {
  background-color: var(--color-blue-700);
  padding: 26px 0;
}

.footer__wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__items {
  align-items: center;
  gap: clamp(16px, 3.4vw, 48px);
  display: flex;
}

@media only screen and (max-width: 768px) {
  .footer__wrapper {
    text-align: center;
    flex-direction: column-reverse;
    gap: 24px;
  }
}

/*# sourceMappingURL=main.css.map */
