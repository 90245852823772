@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.faq {
  &__item {
    overflow: hidden;
    background-color: var(--color-blue-100);
    border-radius: var(--radius-main);

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  details {
    overflow: hidden;

    &[open] {
      summary {
        padding: 16px 56px 13px 24px;
      }

      summary::before {
        rotate: 0deg;
        filter: brightness(0) saturate(100%) invert(15%) sepia(92%) saturate(4918%) hue-rotate(186deg) brightness(92%) contrast(101%);
      }

      summary + div {
        padding: 0 56px 16px 24px;
      }
    }
  }

  summary {
    position: relative;
    padding: 16px 56px 16px 24px;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.02em;
    font-weight: var(--fw-600);
    color: var(--color-blue-700);
    cursor: pointer;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      right: 24px;
      top: 12px;
      overflow: hidden;
      width: 32px;
      height: 32px;
      background-image: url("../images/icons/collapse-icon.svg");
      transition: rotate 0.2s ease-in-out, filter 0.4s ease-in;
      rotate: 180deg;
    }

    + div {
      padding: 0;

      p {
        font-size: 16px;
        line-height: 1.6;
        color: var(--color-dark);
      }
    }
  }

  @include media(tablet) {
    h2 {
      margin-bottom: 24px;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    summary {
      line-height: 1.6;

      &::before {
        top: 16px;
        right: 16px;
      }
    }

    details {
      &[open] {
        summary + div {
          padding-right: 16px;
        }
      }
    }
  }
}