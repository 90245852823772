/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --content-width: 1216px;
  --content-large: 1344px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-large: calc(var(--content-large) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Work Sans", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dark: #333333;
  --color-dark-600: #2C2C2E;

  --color-blue-50: #f7fcff;
  --color-blue-100: #d6e4ec;
  --color-blue-400: #006994;
  --color-blue-512 : rgba(45, 167, 223, 0.12);
  --color-blue-700: #001f2d;
  --color-blue-764: rgba(0, 31, 45, 0.64);
  --color-blue-900: #292e3d;
  //  radius
  --radius-main: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-full: 50%;

  // shadow
  --shadow-primary: 0px 8px 16px 0px #00000014;
  --shadow-blue: 0px 4px 40px 0px #2B59FF14;

  // gradient
  --gradient-main: linear-gradient(270deg, rgba(231,243,249,0) 0%, rgba(231,243,249,1) 100%);
  --gradient-full: linear-gradient(240deg, rgba(231,243,249,0.14) -25%, #E7F3F8 85%);
}