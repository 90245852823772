@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.section {
  $root: &;
  padding: clamp(34px, 6vw, 86px) 0;

  &__info {
    @include flex-all-sb;
    flex-direction: column;
    gap: 26px;
    margin-bottom: clamp(24px, 3.4vw, 48px);
    text-align: center;
  }

  &--more {
    & > *:nth-last-child(2) {
      margin-bottom: clamp(24px, 3.4vw, 47px);
    }
  }

  &--discover,
  &--master,
  &--team {
    #{$root}__title {
      font-size: clamp(26px, 3.4vi, 48px);
    }
  }

  &--discover {
    #{$root}__info {
      gap: clamp(24px, 3.4vw, 50px);
    }
  }

  &--accent {
    background-color: var(--color-blue-400);

    #{$root}__title {
      color: var(--color-white);
    }
  }

  &--journey {
    padding: clamp(34px, 5.3vw, 78px) 0;
  }

  &--values {
    background-color: var(--color-blue-50);
  }

  &--team {
    padding-bottom: clamp(34px, 7.4vw, 108px);

    #{$root}__info {
      margin-bottom: clamp(24px, 4.5vw, 64px)
    }
  }
}