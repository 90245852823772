@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  h2,
  p,
  li,
  a {
    font-family: var(--font-family-secondary);
    letter-spacing: 0.02em;
  }

  h2,
  p,
  li {
    color: var(--color-dark);
  }

  h1 {
    margin-bottom: clamp(24px, 3.4vw, 48px);
    font-size: clamp(32px, 3.4vi, 48px);
    text-align: center;
  }

  h2 {
    margin-bottom: 24px;
    font-family: var(--font-family-secondary);
    font-size: clamp(26px, 3vi, 30px);
    color: var(--color-blue-400);
  }

  .policy__content:not(:last-child) {
    margin-bottom: clamp(26px, 3.4vi, 48px);
  }

  .policy__content > *:not(:last-child, h2) {
    margin-bottom: clamp(16px, 3vw, 32px);
  }

  ul {
    padding-left: clamp(16px, 3vw, 32px);
  }

  li {
    padding-left: 32px;
    position: relative;

    &::before {
      content: "\2714";
      position: absolute;
      left: 0;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  a {
    display: inline;
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  strong {
    font-weight: var(--fw-500);
  }
}