@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  &__inner {
    @include cover-background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    min-height: clamp(450px, 50.4vw, 726px);
    padding-top: var(--header-height);
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 560px;
    width: 100%;

    h1 {
      margin-bottom: 24px;
    }

    .desc {
      margin-bottom: 32px;
    }
  }

  @include media(tablet) {
    &__content {
      width: 50%;
    }
  }

  @include media(mobile-l) {
    &__content {
      width: 100%;
    }
  }
}