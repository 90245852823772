@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.box {
  $root: &;
  @include flex-all-sb;
  gap: 24px;

  &__content {
    max-width: 596px;
    width: 100%;
  }

  &__title {
    margin-bottom: 24px;
    font-size: clamp(26px, 3.4vi, 48px);
  }

  &__desc {
    margin-bottom: clamp(24px, 2.3vw, 32px);
    font-family: var(--font-family-secondary);
  }

  &__items {
    li {
      &:not(:last-of-type) {
        margin-bottom: clamp(16px, 2.3vw, 32px);
      }

      p {
        display: inline;
        line-height: 1.6;
        color: var(--color-blue-700);
      }

      span {
        font-weight: var(--fw-700);
        color: var(--color-blue-400);
      }
    }
  }

  img {
    border-radius: var(--radius-m);
  }

  &--wide {
    #{$root}__content {
      max-width: 699px;
    }

    #{$root}__title {
      margin-bottom: clamp(24px, 2.3vw, 32px);
    }
  }

  @include media(tablet) {
    flex-direction: column;

    &__content {
      max-width: 100%;
    }

    img {
      width: 100%;
    }

    &--journey {
      img {
        aspect-ratio: 3/2;
      }
    }

    &--reverse {
      flex-direction: column-reverse;
    }
  }
}