@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.cards {
  $root: &;

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: 24px clamp(12px, 1.7vw, 24px);
  }

  &__item {
    overflow: hidden;
    border: 1px solid var(--color-blue-100);
    border-radius: var(--radius-main);

    img {
      width: 100%;
    }
  }

  &__content {
    @include flex-sb;
    flex-direction: column;
    gap: 8px;
    padding: 24px clamp(12px, 1.7vw, 24px);
    text-align: center;

    h3:not(.cards__person) {
      color: var(--color-blue-900);
    }

    p {
      color: var(--color-dark);
    }
  }

  &__name {
    font-size: clamp(18px, 2vi, 20px);
    line-height: 1.4;
  }

  &__text {
    font-size: 16px;
    line-height: 1.6;
  }

  &__person,
  &__position {
    color: var(--color-dark);
  }

  &__position {
    margin-bottom: 8px;
    font-weight: var(--fw-600);
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.02em;
  }

  &--three {
    #{$root}__items {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--colored {
    #{$root}__item {
      background-color: var(--color-blue-100);
      border: none;
    }
  }

  &--accent {
    padding: clamp(24px, 3.4vw, 48px);
    background-color: var(--color-blue-100);
    border-radius: var(--radius-main);
    overflow: hidden;

    #{$root}__title {
      margin-bottom: 25px;
      font-size: clamp(22px, 2.2vi, 32px);
      line-height: 1.21;
      font-weight: var(--fw-600);
      color: var(--color-blue-400);
      text-align: center;
      text-transform: capitalize;
    }

    #{$root}__item {
      border: none;
    }

    #{$root}__content {
      background-color: var(--color-blue-400);
      gap: clamp(12px, 1.4vw, 16px);
      padding: clamp(12px, 1.7vw, 24px) clamp(12px, 1.4vw, 16px);
      height: 100%;

      h3,
      p {
        color: var(--color-white);
      }
    }
  }

  &--rounded {
    #{$root}__item {
      border-radius: var(--radius-l);

      img {
        height: revert-layer;
      }
    }

    #{$root}__content {
      text-align: left;

      p {
        font-family: var(--font-family-secondary);
      }
    }

    #{$root}__name {
      line-height: 1.2;
      letter-spacing: 0;
    }
  }

  &--shadow {
    #{$root}__item {
      border: none;
      box-shadow: var(--shadow-primary);
    }

    #{$root}__content {
      text-align: left;
    }
  }

  @include media(tablet) {
    &--accent {
      #{$root}__content {
        justify-content: flex-start;
      }
    }
  }

  @include media(mobile-l) {
    &__items {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: auto;
      align-items: stretch;
    }

    &--three {
      #{$root}__items {
        grid-template-columns: repeat(2, 1fr);
      }

      #{$root}__item {
        &:last-child {
          grid-column: span 2;
        }
      }
    }
  }

  @include media(mobile-m) {
    &--three {
      #{$root}__items {
        grid-template-columns: 1fr;
      }

      #{$root}__item {
        &:last-child {
          grid-column: auto;
        }
      }
    }
  }

  @include media(mobile-s) {
    &__items {
      grid-template-columns: 1fr;
    }
  }
}