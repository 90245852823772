@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  padding-top: 24px;
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-100%);
  }

  @include media(tablet) {
    padding-top: 10px;
  }
}