@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(18px, 2vi, 20px);
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-blue-900);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &--rental {
    .section--featured {
      :nth-last-child(2) {
        margin-bottom: 0;
      }

      .btn {
        display: none;
      }
    }
  }

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

  &--large {
    max-width: var(--container-large);

    @include media(tablet) {
      max-width: var(--container-width);
    }
  }
}

.site-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  &--no-hero {
    padding-top: var(--header-height);
  }
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
    background: var(--gradient-main);
  }

  @include media(tablet) {
    &::before {
      background: var(--gradient-full);
    }
  }
}

.form {
  max-width: 800px;
  width: 100%;
  margin-inline: auto;
  padding: clamp(12px, 2.4vw, 34px) clamp(10px, 2.3vw, 32px);
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  box-shadow: var(--shadow-blue);

  & > *:not(:nth-last-child(2), :last-child) {
    margin-bottom: 16px;
  }

  & > *:nth-last-child(2) {
    margin-bottom: 24px;
  }
}

label {
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  background-color: var(--color-blue-100);
  border-radius: var(--radius-main);
}

input {
  padding: 14.5px;
}

textarea {
  min-height: 156px;
  padding: 16px;
}