@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.keys {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: clamp(32px, 4.5vw, 64px) clamp(12px, 2.3vw, 32px);

  &__item {
    position: relative;
    padding-top: 120px;
    text-align: center;

    &::before {
      @include auto-background;

      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 96px;
      height: 96px;
      background-color: var(--color-blue-512);
      border-radius: var(--radius-m);
    }

    &--innovation::before {
      background-image: url("../images/icons/innovation-icon.svg");
    }

    &--integrity::before {
      background-image: url("../images/icons/integrity-icon.svg");
    }

    &--quality::before {
      background-image: url("../images/icons/quality-icon.svg");
    }

    &--sustainability::before {
      background-image: url("../images/icons/sustainability-icon.svg");
    }

    &--customer::before {
      background-image: url("../images/icons/customer-icon.svg");
    }

    &--teamwork::before {
      background-image: url("../images/icons/teamwork-icon.svg");
    }

    .title {
      font-size: clamp(16px, 2vi, 24px);
    }
  }

  @include media(mobile-m) {
    &__item {
      padding-top: 80px;

      &::before {
        width: 62px;
        height: 62px;
        background-size: 50%;
      }
    }
  }

  @include media(mobile-m) {
    grid-template-columns: repeat(2, 1fr);
  }
}