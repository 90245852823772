@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  &__inner {
    padding: clamp(16px, 3.8vw, 56px) 0;
  }

  &__bottom {
    background-color: var(--color-blue-700);
    padding: 26px 0;
  }

  &__wrapper {
    @include flex-all-sb;
  }

  &__items {
    @include flex-v-center;
    gap: clamp(16px, 3.4vw, 48px);
  }

  @include media(mobile-l) {
    &__wrapper {
      flex-direction: column-reverse;
      gap: 24px;
      text-align: center;
    }
  }
}