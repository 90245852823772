@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.blog {
  padding: clamp(34px, 5vw, 72px) 0;

  &__nav {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: flex-start;
    gap: clamp(16px, 3vw, 40px);
    margin-bottom: clamp(24px, 3.4vw, 48px);
  }

  &__back {
    position: relative;
    padding-block: 11px;
    padding-left: 52px;
    font-size: 18px;
    line-height: 1;
    font-weight: var(--fw-500);
    color: var(--color-dark-600);
    transition: color 0.4s ease-in-out;

    &::before {
      @include cover-background;
      content: "";
      position: absolute;
      left: 24px;
      top: 50%;
      width: 18px;
      height: 18px;
      transform: translateY(-50%);
      background-image: url("../images/icons/back-icon.svg");
      filter: none;
      transition: filter 0.4s ease-in-out;
    }

    &:hover {
      color: var(--color-blue-400);
    }

    &:hover::before {
      filter: brightness(0) saturate(100%) invert(24%) sepia(96%) saturate(1717%) hue-rotate(175deg) brightness(90%) contrast(102%);
    }
  }

  h1 {
    width: 76%;
    font-weight: var(--fw-700);
    color: var(--color-black);
    font-size: clamp(26px, 3vi, 36px);
  }

  &__img {
    margin-bottom: clamp(32px, 4.5vw, 64px);
    border-radius: var(--radius-l);
  }

  &__body {
    & > *:not(:last-child, .blog__img) {
      margin-bottom: clamp(24px, 3.4vw, 48px);
    }

    p,
    li,
    strong,
    a {
      line-height: 1.21;
      color: var(--color-dark-600);
      letter-spacing: 0.02em;
    }
  }

  @include media(mobile-l) {
    h1 {
      width: 100%;
    }

    &__back {
      padding-left: 34px;

      &::before {
        left: 0;
      }
    }

    &__img {
      border-radius: var(--radius-main);
    }
  }

  @include media(mobile-s) {
    &__nav {
      grid-template-columns: 1fr;
    }

    &__img {
      aspect-ratio: 1 / 1;
    }
  }
}