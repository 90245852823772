/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  position: relative;
  display: block;
  width: fit-content;
  margin: 0;
  padding: 10px 15px;
  border: 1px solid var(--color-blue-400);
  border-radius: var(--radius-main);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  font-size: clamp(16px, 2vi, 18px);
  line-height: 1;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  color: var(--color-blue-700);
  background-color: transparent;
  transition-property: background-color, color, border-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &--reverse,
  &:hover {
    color: var(--color-white);
    background-color: var(--color-blue-400);
  }

  &--reverse {
    &:hover {
      background-color: var(--color-white);
      color: var(--color-blue-700);
    }
  }

  &--l {
    padding: 18px 23px;
  }

  &--xl {
    padding: 17px 31px;
  }

  &--centered {
    margin-inline: auto;
  }

  &--send {
    text-transform: initial;
    padding: 14px 23px;
  }

  @include media(mobile-l) {
    &--send {
      width: 100%;
    }
  }
}